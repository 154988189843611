<template>
    <div class="keywords-list">
        <half-circle-spinner
            :animation-duration="1000"
            :size="60"
            v-if="loading"
            class="keywords-loading"
            color="#ff1d5e"/>
        <div class="keywords" v-else>
            <h2 class="title">{{$t("food.backoffice.keywordsListTitle")}}</h2>
            <b-list-group class="list">
                <div class="keyword-wrapper" v-for="(keyword, key) in keywordsToShow" :key="key" >
                    <div :class="{'list-group-item': true, 'keyword': true, 'blink': keyword.suspect}" @click="getBasket(keyword.word[$i18n.locale])" v-touch:swipe.left="leftSwipeHandler(keyword)" v-touch:swipe.right="rightSwipeHandler()">
                            
                        <div class="word">
                            <div class="word-label">{{keyword.word[$i18n.locale]}}</div>
                            <div class="usage-date">{{keyword.dateUsage.toLocaleString($i18n.locale + "-FR")}}</div>
                        </div>
                        <span class="amount" v-if="keyword.amount"> <div v-if="keyword.suspect" class="warning" :style="{'backgroundImage' : `url(${require('@/assets/img/alert-circle.png')})`}"></div> {{keyword.amount.toFixed(2)}} €</span>
                    </div>
                    <div :style="{width: swipe == keyword.id ? '40%' : '0'}" class="actions">
                        <div class="action disapprove" @click="reject(keyword)" :style="{backgroundImage: `url('${require('@/assets/img/disapprove.png')}')`}"></div>
                        <div class="action approve" @click="approve(keyword)" :style="{backgroundImage: `url('${require('@/assets/img/approve.png')}')`}"></div>
                    </div>
                </div>
            </b-list-group>
        </div>
                
        <div class="footer">
            <div class="actions">
                <button class="btn" >{{$t("food.backoffice.backToHome")}}</button>
            </div>
        </div>
    </div>
</template>
<script>
import {getBasketByKeyword, getAllKeywords, getBasketsByDemoId} from "@/assets/js/Food/BackofficeBasketList"
import { HalfCircleSpinner } from 'epic-spinners'
//var MQTT = require("@/assets/js/Food/MqttService");

export default {
    name: "BasketList",
    components: { HalfCircleSpinner },
    data(){
        return{
            keywordsList: [],
            loading: true,
            demoId: this.$route.params.demoId,
            approvedKeywords: JSON.parse(sessionStorage.getItem("DKC_approved_keywords")) || [],
            error: sessionStorage.getItem("basket-keyword-error") == 'true',
            swipe: -1,
            basketsList: []
        }
    },
    mounted(){
        if(this.error){
            this.$emit("showAlert", {message: "Basket not found !", type: "error"} );
            sessionStorage.removeItem("basket-keyword-error");
        }
        getAllKeywords(keywordsList => {
            this.keywordsList = keywordsList
            
            getBasketsByDemoId(this.$route.params.demoId, baskets => {
                this.basketsList = baskets.filter(basket => {
                    return basket.keyword != null && basket.keyword.toLowerCase() != 'null' && keywordsList.filter(keyword => keyword.word[this.$i18n.locale] == basket.keyword || keyword.word.fr == basket.keyword ).length > 0 && basket.state == "FINISH"
                })
                this.loading = false;
            })
        })
    },
    methods: {
        getBasket(keyword){
            this.loading = true;
            this.$router.push({path: "/backoffice/" + this.demoId + "/"+ this.$i18n.locale +"/basket/keyword/" + keyword});
        },
        approve(keyword){
            setTimeout(() => {
                /*this.approvedKeywords.push(keyword.word[this.$i18n.locale]);
                sessionStorage.setItem("DKC_approved_keywords", JSON.stringify(this.approvedKeywords));
                this.loading = true;*/
                //MQTT.publishMessage("BACKOFFICE_APPROVE");
                setTimeout(() => {
                    this.$emit("showAlert", {message: "The basket : " + keyword.word[this.$i18n.locale].toUpperCase() + " is approved", type: "success"} );
                    this.loading = false;
                }, 200)
            }, 500)
        },
        reject(keyword){
            //MQTT.publishMessage("BACKOFFICE_REJECT");
        },
        rightSwipeHandler(keyword){
            return (direction, event) => {
                this.swipe = -1;
            }
        },
        leftSwipeHandler(keyword){
            return (direction, event) => {
                this.swipe = keyword.id;
            }
        },
        getLastUpdatedBasket(){
            this.basketsList.forEach(basket => {
                if(typeof basket.updateDate == "string"){
                    let updateDate = basket.updateDate.split(" ");
                    let updateDay = updateDate[0].split("/")
                    let updateTime = updateDate[1].split(":")
                    basket.updateDate = new Date(updateDay[2], updateDay[1] - 1, updateDay[0], updateTime[0], updateTime[1])
                }
            })
            return this.basketsList.sort((b1, b2) => b2.updateDate - b1.updateDate)
        },
        movedHandler(e){
            console.log(e)
        }
    },
    computed:{
        keywordsToShow(){
            let baskets = this.getLastUpdatedBasket()
            let keywordsToShow = this.keywordsList
            keywordsToShow.forEach((keyword, id) => {
                if(typeof keyword.dateUsage == "string"){
                    let updateDate = keyword.dateUsage.split(" ");
                    let updateDay = updateDate[0].split("/")
                    let updateTime = updateDate[1].split(":")
                    keyword.dateUsage = new Date(Date.UTC(updateDay[2], updateDay[1] - 1, updateDay[0], updateTime[0], updateTime[1]))
                }
                let relatedBasket = baskets.filter(basket => basket.keyword == keyword.word[this.$i18n.locale])[0]
                
                keyword.amount =  5.5
                keyword.suspect =  null

                if(relatedBasket){
                    keyword.amount =  relatedBasket.totalAmount
                    keyword.suspect = relatedBasket.suspect == true 
                }
            });
            keywordsToShow = keywordsToShow.sort((k1, k2) => k2.dateUsage - k1.dateUsage);
            
            if(keywordsToShow[4] && keywordsToShow[4].suspect == null){
                keywordsToShow[4].suspect = true;
            }
            if(keywordsToShow[2] && keywordsToShow[2].suspect == null){
                keywordsToShow[2].suspect = true;
            }
            return keywordsToShow;
        }
    }
}
</script>